<template>
  <div></div>
</template>
<script>
export default {
  name: "MainFrame",
   components: {
    

  },
  data() {
    return {
      
    };
  },
  computed: {

  },
  methods: {

  },
};
</script>